<template>
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <section>
    <div v-for="(elem, index) in items" :key="elem.id">
      <div :class="['py-1 py-md-2', { 'border-bottom': elem.nodeDepth < 3 && items.length !== index+1 }]">
        <b-form-checkbox
          :checked="itemsIdsSelected"
          :value="elem.id"
          :class="[elem.nodeDepth < 3 ? 'font-weight-bold' : 'font-weight-light']"
          stacked
          @change="updateItemsSelected(elem.id)"
        >
          <div>{{ elem.name | trans }}</div>
        </b-form-checkbox>
        <tree-items
          :key="'child' + elem.id"
          class="ml-1"
          :items="elem.children"
          :items-ids-selected="itemsIdsSelected"
          @change="updateItemsSelected($event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import TreeItems from '@/components/TreeItems.vue'

// https://vuejsdevelopers.com/2017/10/23/vue-js-tree-menu-recursive-components/

export default defineComponent({
  name: 'Tree',

  components: { TreeItems },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsIdsSelected: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const { _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const localSelectedItems = ref(_cloneDeep(props.itemsIdsSelected))

    const updateItemsSelected = catId => {
      let payload = _cloneDeep(props.itemsIdsSelected)
      if (payload.includes(catId)) {
        payload = payload.filter(el => el !== catId)
      } else {
        payload.push(catId)
      }
      $emit('items:selected', payload)
    }

    return {
      localSelectedItems,
      updateItemsSelected,
    }
  },
})
</script>
