<template>
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <!-- DO NOT USE -->
  <section>
    <div
      v-for="child in items"
      :key="child.id"
    >
      <b-form-checkbox
        :checked="localSelectedItems"
        :value="child.id"
        :class="[child.nodeDepth < 3 ? 'font-weight-light' : 'font-weight-bold']"
        stacked
        @change="$emit('change', child.id)"
      >
        <div :class="{ 'mb-50': child.nodeDepth >= 3 }">
          {{ child.name | trans }}
        </div>
      </b-form-checkbox>
      <tree
        :key="'child' + child.id"
        :item="child.children"
        class="ml-1"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'TreeItems',

  components: {
    Tree: () => import('@/components/Tree.vue'),
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsIdsSelected: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, ctx) {
    const { _cloneDeep } = ctx.root
    const localSelectedItems = ref(_cloneDeep(props.itemsIdsSelected))

    return {
      localSelectedItems,
    }
  },
})
</script>
