<template>
  <section>
    <!--HEADER-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>

    <!--ACTION-->
    <section class="d-flex justify-content-end">
      <b-button
        variant="primary"
        class="mr-1"
        :to="{
          name: 'shop-checkout',
          params: {
            trip_id: this.$route.params.trip_id,
            step_id: this.$route.params.step_id,
            event_id: this.$route.params.event_id,
            airport_id: this.$route.params.airport_id,
          },
        }"
      >
        <feather-icon class="mr-50" icon="ShoppingCartIcon" />
        {{ $t('trip.shop.go_to_cart') }}
      </b-button>
      <b-button variant="primary" :to="{ name: 'trip-view', params: { trip_id: this.$route.params.trip_id } }">
        <font-awesome-icon icon="stream" class="mr-50" />
        {{ $t('trip.return_to_timeline') }}
      </b-button>
    </section>

    <b-row v-if="providers === undefined && totalItems === 0">
      <b-col cols="12">
        <b-alert variant="primary" show class="m-0">
          <div class="alert-body">
            <span>{{ $t('alert.provider.no_provider_for_aiport') }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-else>
      <div style="height: inherit" class="ecommerce-application match-height col-12">
        <!-- Sidebar -->
        <ShopLeftFilterSidebar
          :params="params"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @categorieSelected="params.categoriesIds = $event"
          @refetch-product="APIFetchProvidersForAirportRequest()"
        />
        <!-- Provider Header -->
        <section id="ecommerce-header">
          <div class="row">
            <div class="col-sm-12">
              <div class="ecommerce-header-items py-50">
                <div class="result-toggler">
                  <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" />
                  <div class="search-results">{{ totalItems }} {{ $tc('trip.shop.result_found', totalItems) }}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Overlay -->
        <div class="body-content-overlay" />

        <!-- Searchbar -->
        <div class="ecommerce-searchbar mb-1">
          <b-row>
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input v-model="params.filter" debounce="500" :placeholder="$t('action.search')" class="search-product" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <b-overlay :show="loadProviders" spinner-medium>
              <section class="grid-view">
                <div v-for="providerEntity in providers" :key="providerEntity.provider.id" md="6" lg="4">
                  <b-overlay :show="!providerEntity.categories.length">
                    <b-card
                      class="wrapper-img-provider-banner"
                      style="overflow: hidden"
                      :img-src="providerEntity.provider.bannerFileUrl ? providerEntity.provider.bannerFileUrl : require('@/assets/images/banner/airplane.jpg')"
                      img-top
                      no-body
                    >
                      <b-badge :show="providerEntity.provider.feesCollector" class="badge-fees_collector" variant="primary">
                        <span>{{ $t('provider.fees_collector') }}</span>
                      </b-badge>
                      <b-container fluid>
                        <div class="provider-action">
                          <b-button :id="'categories' + providerEntity.provider.id.toString()" class="btn-icon" variant="light">
                            <font-awesome-icon size="md" icon="list" />
                          </b-button>
                          <b-tooltip placement="topLeft" variant="light" boundary-padding="0" :target="'categories' + providerEntity.provider.id.toString()">
                            <b-card v-if="providerEntity.categories.length" class="m-0" border-variant="primary">
                              <div>
                                <h6 class="pb-0 mb-0 text-left">
                                  {{ $t('provider.categories') }}
                                </h6>
                                <div class="mb-2">
                                  <ul class="text-left d-inline">
                                    <li v-for="(category, index) in providerEntity.categories" :key="index">
                                      {{ category.name | trans }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </b-card>

                            <b-card v-else border-variant="primary">
                              {{ $t('provider.no_categories') }}
                            </b-card>
                          </b-tooltip>

                          <b-button
                            :id="'assets' + providerEntity.provider.id.toString()"
                            class="btn-icon ml-50"
                            variant="light"
                            :to="{
                              name: 'provider-details',
                              params: {
                                provider_id: providerEntity.provider.id,
                                trip_id: $route.params.trip_id,
                                step_id: $route.params.step_id,
                                event_id: $route.params.event_id,
                                airport_id: $route.params.airport_id,
                              },
                            }"
                          >
                            <font-awesome-icon icon="home" />
                          </b-button>
                          <b-tooltip placement="topLeft" variant="light" boundary-padding="0" :target="'assets' + providerEntity.provider.id.toString()">
                            <b-card
                              v-if="
                                providerEntity.provider.aircraftFacilities.length ||
                                  providerEntity.provider.crewFacilities.length ||
                                  providerEntity.provider.passengerFacilities.length
                              "
                              class="m-0"
                              border-variant="primary"
                            >
                              <div v-if="providerEntity.provider.aircraftFacilities.length">
                                <h6 class="pb-0 mb-0 text-left">
                                  {{ $t('provider.aircraft_facilities') }}
                                </h6>
                                <div class="mb-2">
                                  <ul class="text-left d-inline">
                                    <li v-for="(aircraftFacility, index) in providerEntity.provider.aircraftFacilities" :key="index">
                                      {{ aircraftFacility.aircraftFacilityValue | enumTranslate('aircraft_facility_value') }}
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div v-if="providerEntity.provider.crewFacilities.length">
                                <h6 class="pb-0 mb-0 text-left">
                                  {{ $t('provider.crew_facilities') }}
                                </h6>
                                <div class="mb-2">
                                  <ul class="text-left d-inline">
                                    <li v-for="(crewFacility, index) in providerEntity.provider.crewFacilities" :key="index">
                                      {{ crewFacility.crewFacilityValue | enumTranslate('crew_facility_value') }}
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div v-if="providerEntity.provider.passengerFacilities.length">
                                <h6 class="pb-0 mb-0 text-left">
                                  {{ $t('provider.passenger_facilities') }}
                                </h6>
                                <div class="mb-2">
                                  <ul class="text-left d-inline">
                                    <li v-for="(passengerFacility, index) in providerEntity.provider.passengerFacilities" :key="index">
                                      {{ passengerFacility.passengerFacilityValue | enumTranslate('passenger_facility_value') }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </b-card>
                            <b-card v-else border-variant="primary">
                              {{ $t('provider.no_facilities') }}
                            </b-card>
                          </b-tooltip>
                        </div>
                      </b-container>
                      <div class="card-body_provider">
                        <div class="wrapper-img-provider">
                          <b-img
                            :src="providerEntity.provider.logoFileUrl ? providerEntity.provider.logoFileUrl : require('@/assets/images/logo/logo.png')"
                            rounded="circle"
                            alt="Circle image"
                            class="d-inline-block img-provider"
                          />
                        </div>
                        <section class="text-center mt-5">
                          <b-card-title>
                            {{ providerEntity.provider.legalName }}
                          </b-card-title>
                          <b-card-sub-title>
                            {{ providerEntity.provider.commercialName }}
                          </b-card-sub-title>
                        </section>
                        <b-card-text class="mt-2 px-1">
                          <!-- eslint-disable vue/no-v-html -->
                          <span class="provider-description" v-html="providerEntity.provider.description" />
                        </b-card-text>
                      </div>
                      <div class="item-options text-center">
                        <b-button v-if="false" variant="light" tag="a" class="btn-wishlist">
                          <feather-icon icon="HeartIcon" class="mr-50" />
                          <span>{{ $t('provider.add_to_favorites') }}</span>
                        </b-button>
                        <b-button
                          style="z-index: 11"
                          tag="a"
                          class="btn-cart"
                          :to="{
                            name: 'provider-details',
                            params: {
                              provider_id: providerEntity.provider.id,
                              trip_id: $route.params.trip_id,
                              step_id: $route.params.step_id,
                              event_id: $route.params.event_id,
                              airport_id: $route.params.airport_id,
                            }
                          }"
                        >
                          <feather-icon icon="EyeIcon" class="mr-50" />
                          <span>{{ $t('action.show') }}</span>
                        </b-button>
                      </div>
                    </b-card>
                    <template #overlay>
                      <div />
                    </template>
                  </b-overlay>
                </div>
              </section>
            </b-overlay>
          </div>
        </div>

        <!-- PAGINATION -->
        <section v-if="providers && providers.length && totalItems !== 0">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">
                {{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }} {{ dataMeta.of }}
                {{ $tc('pagination.entries', dataMeta.of) }}
              </span>
            </b-col>
            <b-pagination
              v-model="params.page"
              :total-rows="totalItems"
              :per-page="params.numberOfItemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-row>
        </section>
      </div>
    </b-row>
  </section>
</template>

<script>
import TripView from '@/views/trip/trip-view/TripView.vue'
import ShopLeftFilterSidebar from '@/views/shop/shop/flight/ShopListLeftFilterSidebar.vue'
import { fetchProvidersForAirportRequest } from '@/request/globalApi/requests/catalogueRequests'

export default {
  name: 'ProviderList',

  components: {
    TripView,
    ShopLeftFilterSidebar,
  },

  data() {
    return {
      mqShallShowLeftSidebar: false,
      providers: [],
      loadProviders: true,
      tripData: {},
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'workrole', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions', sortable: false, tdClass: 'actionCol' },
      ],
      totalItems: 0,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        legalNameOrder: null,
        // SEARCH
        filter: null,
        categoriesIds: [],
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: '',
      sortDesc: '',
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    dataMeta() {
      const localItemsCount = this.providers.length ? this.providers.length : 0
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        getAllItems: this.params.getAllItems,
        // SORTING
        legalNameOrder: this.params.legalNameOrder,
        // SEARCH
        filter: this.params.filter,
        categoriesIds: this.params.categoriesIds,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.APIFetchProvidersForAirportRequest()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchTrip()
    this.APIFetchProvidersForAirportRequest()
  },
  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: true })
      if (trip) {
        this.tripData = trip
      }
    },
    APIFetchProvidersForAirportRequest() {
      this.loadProviders = true

      const airportId = Number(this.$route.params.airport_id)
      fetchProvidersForAirportRequest(airportId, this.params)
        .then(r => {
          const { providers, totalItems } = r.data
          this.providers = providers
          this.totalItems = totalItems
        })
        .catch(() => {
          this.providers = undefined
        })
        .finally(() => {
          this.loadProviders = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';

.list-view .shop-img {
  height: 7rem;
}
</style>
<style lang="scss" scoped>
.provider-action {
  position: absolute;
  top: 3%;
  right: 3%;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  display: initial;
}
.shop-img {
  width: 100%;
  height: 15rem;
  object-fit: contain;
}

.badge-price {
  position: absolute;
  right: 8px;
  top: 8px;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style scoped lang="scss">
.badge-fees_collector {
  position: absolute;
  top: 3%;
  left: 3%;
}

.item-options {
  display: flex;
  flex-wrap: wrap;
  .btn {
    flex: 1;
    border-radius: 0;
  }
}

.provider-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.wrapper-img-provider-banner > img {
  max-height: 180px;
  object-fit: cover;
}
.card-body_provider {
  position: relative;
  max-height: 220px;
  .wrapper-img-provider {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-provider {
      position: absolute;
      top: -50%;
      width: 100px;
      height: 100px;
      object-fit: contain;
      border: 1px solid #f6f6f6;
      background-color: #fff;
    }
  }
}
</style>
